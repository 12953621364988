// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kf-1ejzD{flex:1;width:100%;margin:0 2.5rem}.kf-3vdvd{padding:1.5rem;background:var(--color-background);border-radius:0.625rem}.kf-24eK0{margin:0 0 1.5rem;font-size:1rem;line-height:150%;letter-spacing:-0.02em;color:var(--color-gray900)}.kf-2lzSK{display:flex;flex-direction:row;margin:-0.25rem}.kf-2L6Yj{padding:0.25rem;flex:1}.kf-1VKtq{display:flex;align-items:center;justify-content:center;width:100%;border-radius:0.3125rem;height:2.5rem;font-weight:bold;font-size:1rem;line-height:1.375rem;text-align:center;letter-spacing:-0.02em;-webkit-tap-highlight-color:rgba(0,0,0,0);outline:none !important;position:relative;transition:all 200ms;border:none;background:none}.kf-1VKtq:after{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.05);opacity:0;transition:opacity 200ms}.kf-1VKtq:active:after{opacity:1}.kf-1VKtq:disabled:after{content:\"\"}.kf-1VKtq.kf-21qqp{background:var(--color-background);border:1px solid var(--color-gray300);color:var(--color-gray900)}.kf-1VKtq.kf-21qqp:disabled{background:var(--color-gray100);color:var(--color-gray400);border-color:var(--color-gray200)}.kf-1VKtq.kf-1XlW7{background:var(--color-carrot500);border:none;color:#fff}.kf-1VKtq.kf-1XlW7:disabled{background:var(--color-gray300)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionModalBodyOverrideStyle": "kf-1ejzD",
	"actionModalCard": "kf-3vdvd",
	"actionModalCardBody": "kf-24eK0",
	"actionModalCardActionsWrapper": "kf-2lzSK",
	"actionModalCardActionWrapper": "kf-2L6Yj",
	"actionModalCardAction": "kf-1VKtq",
	"whiteButton": "kf-21qqp",
	"karrotButton": "kf-1XlW7"
};
export default ___CSS_LOADER_EXPORT___;
