import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { motion, AnimatePresence } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'

import RightArrow from '@src/assets/icons/RightArrow'
import { bridge } from '@src/bridge'
import { APP_WEBVIEW_URL } from '@src/constants/config'
import useShowEvent from '@src/hooks/useShowEvent'
import { storage } from '@src/utils/storage'

type Props = {
  hasAdCash: boolean
  hasBizProfile: boolean
  hasDaangnPay: boolean
}

const STAGE = process.env.REACT_APP_ENV || 'alpha'
const IS_PRODUCTION = STAGE === 'production'

const PROTOCOL = IS_PRODUCTION ? 'karrot:' : 'karrot.alpha:'
const PAY_APP_URI = IS_PRODUCTION
  ? 'https://money.daangnpay.com/accounts/sign-out?from=MARKET_APP_SIGN_OUT'
  : 'https://money.dev.daangnpay.com/accounts/sign-out?from=MARKET_APP_SIGN_OUT'
const BIZPROFILE_REMOTE_URI = IS_PRODUCTION
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

const DestroyableSection = ({ hasAdCash, hasBizProfile, hasDaangnPay }: Props) => {
  const { t } = useTranslation()
  const { nickname } = storage.getUserInfo()

  useShowEvent({
    screenName: 'withdraw_disable',
  })

  const sendEventLog = (name: string) => {
    bridge.analytics.log({
      name: 'click_delete_account_exception',
      params: { name },
    })
  }

  const handleAdCash = () => {
    const app = `${APP_WEBVIEW_URL}/kotisaari`
    const path = '/finances/refund?recent=true'
    sendEventLog('ad_cash_refund')
    bridge.router.push({
      app,
      path,
      navbar: false,
      scrollable: false,
      present: true,
    })
  }

  const handleBizProfile = () => {
    const remote = `${BIZPROFILE_REMOTE_URI}?referrer=native.mydaangn.user_destroy`
    sendEventLog('biz_profile_setting')
    bridge.router.push({
      remote,
      navbar: false,
      scrollable: false,
    })
  }

  const handleDaangnPay = () => {
    sendEventLog('pay_refund')
    const webviewOptions = {
      navbar: 'false',
      scrollable: 'false',
      present: 'top',
    }
    const webviewOptionsParams = new URLSearchParams(webviewOptions)
    const payWebviewScheme = `${PROTOCOL}//pay-web/router?remote=${encodeURIComponent(
      PAY_APP_URI
    )}&${webviewOptionsParams.toString()}`

    window.location.href = payWebviewScheme
  }

  return (
    <>
      <header>
        <Title>
          <Trans i18nKey="destroyable_title" values={{ nickname: nickname }} />
        </Title>
      </header>
      <section>
        <AnimatePresence>
          <ul>
            {hasAdCash && (
              <Item
                onClick={handleAdCash}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <ItemInfo>
                  <ItemTitle>{t('destroyable_list_item_1.title')}</ItemTitle>
                  <ItemDescription>{t('destroyable_list_item_1.description')}</ItemDescription>
                </ItemInfo>
                <ItemMoreInfo>
                  <RightArrow />
                </ItemMoreInfo>
              </Item>
            )}
            {hasBizProfile && (
              <Item
                onClick={handleBizProfile}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <ItemInfo>
                  <ItemTitle>{t('destroyable_list_item_2.title')}</ItemTitle>
                  <ItemDescription>{t('destroyable_list_item_2.description')}</ItemDescription>
                </ItemInfo>
                <ItemMoreInfo>
                  <RightArrow />
                </ItemMoreInfo>
              </Item>
            )}
            {hasDaangnPay && (
              <Item
                onClick={handleDaangnPay}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}>
                <ItemInfo>
                  <ItemTitle>{t('destroyable_list_item_3.title')}</ItemTitle>
                  <ItemDescription>{t('destroyable_list_item_3.description')}</ItemDescription>
                </ItemInfo>
                <ItemMoreInfo>
                  <RightArrow />
                </ItemMoreInfo>
              </Item>
            )}
          </ul>
        </AnimatePresence>
      </section>
    </>
  )
}

export default DestroyableSection

const Title = styled.h2`
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 16px;
`

const Item = styled(motion.li)`
  display: flex;
  padding: 24px 0 24px 0;
  border-bottom: 1px solid ${vars.$semantic.color.divider1};
`

const ItemTitle = styled.h3`
  font-size: 18px;
  line-height: 135%;
`

const ItemDescription = styled.p`
  font-size: 16px;
  line-height: 150%;
  margin-top: 8px;
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
`

const ItemMoreInfo = styled.div`
  color: ${vars.$scale.color.gray900};
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
