// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes kf-2HZbe{from{transform:translate3d(0, 1.25rem, 0);opacity:0}to{transform:translate3d(0, 0, 0);opacity:1}}.kf-1jJRX{position:fixed;bottom:0;left:0;right:0;width:100%;box-shadow:0px -1px 3px rgba(0,0,0,0.07);z-index:1;animation:kf-2HZbe 300ms ease}.kf-1jJRX.kf-1aYk_{animation:none}.kf-1jC60 *{box-sizing:border-box}.kf-1jC60{display:flex;align-items:center;background:var(--color-background);min-height:3.5rem}.kf-1jC60 a,.kf-1jC60 button{-webkit-tap-highlight-color:rgba(0,0,0,0);outline:none !important;text-decoration:none}.kf-_TnRW{flex:1;display:flex;padding:0.5rem}.kf-3Z7FP{flex:1;padding:0 0.5rem}.kf-3Zt0K{height:2.5rem;width:100%;display:flex;align-items:center;justify-content:center;border-radius:0.3125rem;font-weight:bold;font-size:1rem;line-height:1.375rem;text-align:center;letter-spacing:-0.02em;position:relative;overflow:hidden;cursor:pointer}.kf-3Zt0K:after{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.05);opacity:0;transition:opacity 200ms}.kf-3Zt0K:active:after{opacity:1}.kf-3Zt0K.kf-1kXrC{pointer-events:none}.kf-3Zt0K.kf-1kXrC:after{content:\"\"}.kf-3Zt0K.kf-yXD9g{background:var(--color-background);border:1px solid var(--color-gray300);color:var(--color-gray900)}.kf-3Zt0K.kf-yXD9g.kf-1kXrC{background:var(--color-gray100);color:var(--color-gray400);border-color:var(--color-gray200)}.kf-3Zt0K.kf-1eHmM{background:var(--color-carrot500);border:none;color:#fff}.kf-3Zt0K.kf-1eHmM.kf-1kXrC{background:var(--color-gray300)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomAppbar": "kf-1jJRX",
	"moveUp": "kf-2HZbe",
	"ignoreAnimation": "kf-1aYk_",
	"appbarWrapper": "kf-1jC60",
	"appbarActionsWrapper": "kf-_TnRW",
	"appbarActionButtonWrapper": "kf-3Z7FP",
	"actionButton": "kf-3Zt0K",
	"disabled": "kf-1kXrC",
	"defaultTheme": "kf-yXD9g",
	"karrotTheme": "kf-1eHmM"
};
export default ___CSS_LOADER_EXPORT___;
