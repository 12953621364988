import styled from '@emotion/styled'
import { useNavigator } from '@karrotframe/navigator'
import { vars } from '@seed-design/design-token'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { v4 } from 'uuid'

import { userAPI } from '@src/apis'
import { bridge } from '@src/bridge'
import Button from '@src/components/Button'
import Title from '@src/components/Title'
import { IS_ANDROID } from '@src/constants/config'
import useShowEvent from '@src/hooks/useShowEvent'
import { selectedReasonTextState } from '@src/store'
import { storage } from '@src/utils/storage'
declare global {
  interface Window {
    AndroidFunction?: {
      accountHandler: (message: string) => void
    }
    webkit?: {
      messageHandlers: {
        accountHandler: {
          postMessage(message: string): void
        }
      }
    }
  }
}

function requestBridge(message: { id: string; type?: string; payload?: any }) {
  if (IS_ANDROID) {
    window.AndroidFunction?.accountHandler(JSON.stringify(message))
  } else {
    window.webkit?.messageHandlers.accountHandler.postMessage(JSON.stringify(message))
  }
}

const DestroySection = () => {
  const { t } = useTranslation()
  const { nickname } = storage.getUserInfo()
  const [submitted, setSubmitted] = useState(false)
  const selectedReasonText = useRecoilValue(selectedReasonTextState)

  const { replace } = useNavigator()

  useShowEvent({
    screenName: 'withdraw_confirm',
  })

  const handleSubmit = async () => {
    setSubmitted(true)
    try {
      bridge.analytics.log({
        name: 'client_clicked_identity_withdraw_confirm_button_v1',
        target: 'KARROT',
        params: {
          button: 'confirm',
        },
      })

      const response = await userAPI.destroy(selectedReasonText)

      if (response.data?.destroyable && response.data?.destroyable.length > 0) {
        const initialUrl = '/users/me/destroy'
        replace(initialUrl)
        return
      }

      let body = t('destroy_success_message')
      if (response.status === 200 && response.data.status && response.data.status.code === 'model_has_errors') {
        body = response.data.status.message
      }

      bridge.toast.open({ body })

      if (response.status === 200 && response.data.status && response.data.status.code === 'ok') {
        requestBridge({ id: v4(), type: 'REQ.ACCOUNT.CLEAR_APP_DATA' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <header>
        <Title>
          <Trans i18nKey="destroy_warning_title" values={{ nickname: nickname }} />
        </Title>
      </header>
      <section>
        <List>
          <Item>{t('destroy_list_item_1')}</Item>
          <Item>{t('destroy_list_item_2')}</Item>
          <Item>{t('destroy_list_item_3')}</Item>
          <Item>{t('destroy_list_item_4')}</Item>
          <Item>{t('destroy_list_item_5')}</Item>
          <Item>{t('destroy_list_item_6')}</Item>
        </List>
        <Description>
          <Trans i18nKey="destroy_description_2" values={{ nickname: nickname }} />
        </Description>
      </section>
      <ButtonSection>
        <DestroyButton onClick={handleSubmit} disabled={submitted}>
          {t('destroy_button')}
        </DestroyButton>
      </ButtonSection>
    </motion.div>
  )
}

export default DestroySection

const List = styled.ul`
  margin-top: 32px;
  margin-left: 18px;
`

const Item = styled.li`
  list-style-type: disc;
  list-style-position: outside;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 150%;
`

const Description = styled.p`
  font-weight: bold;
  margin-top: 32px;

  strong {
    color: ${vars.$semantic.color.danger};
  }
`

const ButtonSection = styled.section`
  margin-top: 24px;
`

const DestroyButton = styled(Button)`
  width: 100%;
  color: ${vars.$static.color.white};
  background: ${vars.$semantic.color.danger};
  border: 1px solid ${vars.$semantic.color.danger};
`
